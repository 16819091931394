/**
 * @generated SignedSource<<eba545cb52f36c11cae4c95683df2c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessAccountIdentityBusinessType = "CORP" | "INC" | "LLC" | "LLP" | "LP" | "LTD" | "OTHER" | "PLC" | "SP" | "%future added value";
export type BusinessAccountIdentityFinalState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBActionLogAction = "approve" | "comment" | "edit_profile" | "escalate" | "in_progress" | "info_request" | "on_hold" | "reject" | "start_kyb" | "submit_profile" | "upload_documents" | "%future added value";
export type KYBActionLogCurrentAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBActionLogPrevAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBActionLogSubjectType = "admin" | "system" | "user" | "%future added value";
export type KYBAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBDocumentType = "BENEFICIAL_OWNER_DECLARATION" | "BOARD_RESOLUTION" | "BUSINESS_DETAILS" | "CERT_INCORPORATION" | "CERT_INCUMBENCY" | "CORPORATE_STRUCTURE_OWNERSHIP" | "MEMORANDUM_ARTICLES" | "OTHER" | "PROOF_SOURCE_FUNDS" | "REGISTER_OF_DIRECTORS" | "REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_DETAILS" | "%future added value";
export type KYBDocumentUploaderRole = "admin" | "user" | "%future added value";
export type KYCAttemptState = "ADDRESS_PROVIDED" | "ADDRESS_REQUIRED" | "AML_CHECKED" | "ANSWER_REQUIRED" | "DOCUMENT_EDITED" | "DONE" | "FAILED" | "IDENTITY_CHECKED" | "IDENTITY_DOCUMENT_SUBMITTED" | "KYC_QUESTION_ANSWERED" | "MANUAL_APPROVE_PENDING" | "NO_KYC" | "POA_REQUIRED" | "POA_SUBMITTED" | "POA_VERIFIED" | "%future added value";
export type KYCTagValue = "AML_HIT" | "DOCUMENT_ATTENTION" | "HIGH_RISK" | "ID_ATTENTION" | "ID_EDITED" | "ID_MISMATCH" | "IP_COUNTRY_MISMATCH" | "ISSUING_COUNTRY_MISSING" | "LOW_RISK" | "MEDIUM_RISK" | "POA_ATTENTION" | "POA_EXPIRED" | "POA_MISMATCH" | "PROHIBITED_COUNTRY" | "UNSUPPORTED_DOCUMENT_TYPE" | "%future added value";
export type UserIdentityKycReviewState = "APPLICATION_NOT_STARTED" | "APPLICATION_ONGOING" | "ESCALATED" | "FAILURE_AUTO_TERMINAL_REPEATED" | "INFO_REQUESTED" | "IN_PROGRESS" | "ON_HOLD" | "PENDING" | "RESTART_CLOSED" | "RESTART_IDENTITY_CHECK_FAILED" | "RESTART_REJECTED" | "SUCCESS_AUTO" | "SUCCESS_MANUAL" | "TERMINAL_BLACKLISTED" | "TERMINAL_IDENTITY_BLACKLISTED" | "%future added value";
export type BusinessAccountIdentityQuery$variables = {
  accountID: string;
};
export type BusinessAccountIdentityQuery$data = {
  readonly businessAccountIdentity: {
    readonly data: {
      readonly accountID: string | null;
      readonly businessType: BusinessAccountIdentityBusinessType;
      readonly createTime: any | null;
      readonly currentKyb: {
        readonly actionLogs: ReadonlyArray<{
          readonly action: KYBActionLogAction;
          readonly createTime: any | null;
          readonly currentAttemptState: KYBActionLogCurrentAttemptState | null;
          readonly data: string | null;
          readonly id: string;
          readonly kybAttemptID: string;
          readonly message: string | null;
          readonly prevAttemptState: KYBActionLogPrevAttemptState | null;
          readonly subjectEmail: string | null;
          readonly subjectType: KYBActionLogSubjectType;
          readonly subjectUserID: string;
          readonly updateTime: any | null;
        }> | null;
        readonly businessAccountIdentityID: string;
        readonly createTime: any | null;
        readonly documents: ReadonlyArray<{
          readonly comment: string | null;
          readonly createTime: any | null;
          readonly fileName: string | null;
          readonly id: string;
          readonly kybAttemptID: string;
          readonly type: KYBDocumentType;
          readonly updateTime: any | null;
          readonly uploaderRole: KYBDocumentUploaderRole;
          readonly uploaderUserID: string;
          readonly url: string;
        }> | null;
        readonly id: string;
        readonly state: KYBAttemptState;
        readonly updateTime: any | null;
      } | null;
      readonly currentKybAttemptID: string | null;
      readonly dateOfIncorporation: any | null;
      readonly doingBusinessAsName: string | null;
      readonly email: string | null;
      readonly finalState: BusinessAccountIdentityFinalState;
      readonly fullLegalName: string | null;
      readonly id: string;
      readonly incorporationCountry: string | null;
      readonly incorporationNumber: string | null;
      readonly legalAddress: string | null;
      readonly phone: string | null;
      readonly primaryAddress: string | null;
      readonly updateTime: any | null;
      readonly userIdentities: ReadonlyArray<{
        readonly accountID: string;
        readonly createTime: any | null;
        readonly currentKyc: {
          readonly kycTags: ReadonlyArray<{
            readonly id: string;
            readonly value: KYCTagValue;
          }> | null;
          readonly state: KYCAttemptState;
        } | null;
        readonly email: string | null;
        readonly firstName: string | null;
        readonly id: string;
        readonly kycReviewState: UserIdentityKycReviewState;
        readonly lastName: string | null;
        readonly updateTime: any | null;
        readonly userID: string;
      }> | null;
      readonly website: string | null;
    };
  };
};
export type BusinessAccountIdentityQuery = {
  response: BusinessAccountIdentityQuery$data;
  variables: BusinessAccountIdentityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountID",
    "variableName": "accountID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullLegalName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "doingBusinessAsName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "businessType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "incorporationCountry",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOfIncorporation",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "incorporationNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legalAddress",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryAddress",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalState",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentKybAttemptID",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kybAttemptID",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "KYBAttempt",
  "kind": "LinkedField",
  "name": "currentKyb",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessAccountIdentityID",
      "storageKey": null
    },
    (v19/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "KYBDocument",
      "kind": "LinkedField",
      "name": "documents",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v20/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploaderUserID",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploaderRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comment",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KYBActionLog",
      "kind": "LinkedField",
      "name": "actionLogs",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v20/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subjectUserID",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subjectType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subjectEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prevAttemptState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentAttemptState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "action",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userID",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kycReviewState",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "KYCTag",
  "kind": "LinkedField",
  "name": "kycTags",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessAccountIdentityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BusinessAccountIdentityStatus",
        "kind": "LinkedField",
        "name": "businessAccountIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessAccountIdentity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserIdentity",
                "kind": "LinkedField",
                "name": "userIdentities",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v22/*: any*/),
                  (v5/*: any*/),
                  (v13/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KYCAttempt",
                    "kind": "LinkedField",
                    "name": "currentKyc",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v26/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessAccountIdentityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BusinessAccountIdentityStatus",
        "kind": "LinkedField",
        "name": "businessAccountIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessAccountIdentity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserIdentity",
                "kind": "LinkedField",
                "name": "userIdentities",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v22/*: any*/),
                  (v5/*: any*/),
                  (v13/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KYCAttempt",
                    "kind": "LinkedField",
                    "name": "currentKyc",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      (v26/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "804d42997c6250a8b39cfe268a5cf700",
    "id": null,
    "metadata": {},
    "name": "BusinessAccountIdentityQuery",
    "operationKind": "query",
    "text": "query BusinessAccountIdentityQuery(\n  $accountID: String!\n) {\n  businessAccountIdentity(accountID: $accountID) {\n    data {\n      id\n      createTime\n      updateTime\n      accountID\n      fullLegalName\n      doingBusinessAsName\n      businessType\n      incorporationCountry\n      dateOfIncorporation\n      incorporationNumber\n      website\n      email\n      phone\n      legalAddress\n      primaryAddress\n      finalState\n      currentKybAttemptID\n      currentKyb {\n        id\n        createTime\n        updateTime\n        businessAccountIdentityID\n        state\n        documents {\n          id\n          createTime\n          updateTime\n          kybAttemptID\n          uploaderUserID\n          uploaderRole\n          url\n          type\n          fileName\n          comment\n        }\n        actionLogs {\n          id\n          createTime\n          updateTime\n          kybAttemptID\n          subjectUserID\n          subjectType\n          subjectEmail\n          prevAttemptState\n          currentAttemptState\n          action\n          message\n          data\n        }\n      }\n      userIdentities {\n        id\n        createTime\n        updateTime\n        userID\n        accountID\n        email\n        firstName\n        lastName\n        kycReviewState\n        currentKyc {\n          state\n          kycTags {\n            id\n            value\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4cd42d2944223a8728a9679a4c8fc14";

export default node;
